<template>
    <div class="container">
        <h1 class="mt-3">{{$t('personalId.title')}}</h1>
        <div class="p-4 bg-white border-light rounded shadow">
            <personal-id-form
                :defaults="personalId || {}"
                :genders="genders"
                :countries="countries"
                :personal-id-types="personalIdTypes"
                :date-picker-lang="datePickerLang"
                :ivan-hash-id="ivanHashId"
                @submit="onSubmit"
                @cancel="onPrevious"
            />
        </div>
    </div>
</template>

<script>
import PersonalIdForm from './Components/PersonalIdForm.vue'
import ViewBase from '../ViewBase.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'PersonalId',
    extends: ViewBase,
    components: {
        PersonalIdForm
    },
    computed: {
        ...mapGetters([
            'genders',
            'countries',
            'personalIdTypes'
        ]),
        clientType () {
            return (this.sessionData) ? this.sessionData.clientType : null
        },
        personalId () {
            return (this.sessionData && this.sessionData.personalId) ? this.sessionData.personalId : null
        }
    },
    methods: {
        onSubmit (personalId) {
            this.saveSessionData({ personalId })
                .then(() => {
                    this.$store.commit('setDataHash', { name: 'personalIdOld', hash: null })
                    this.$store.commit('setDataHash', { name: 'personalId', hash: null })
                    this.$emit('next')
                })
        }
    }
}
</script>

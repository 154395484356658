<template>
    <div>
        <h3>{{$t('personalId.heading')}}</h3>
        <b-form @submit.prevent="onSubmit" :novalidate="true">
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input
                        :label="$t('personalId.documentNumber')"
                        v-model="documentNumber"
                        :validation="$v.documentNumber"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input-date-picker
                        :label="$t('personalId.documentValidity')"
                        :validation="$v.documentValidity"
                        v-model="documentValidity"
                        :min-date="new Date()"
                        :locale="datePickerLang"
                        :date-disabled-fn="(ymd, date) => date <= new Date()"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :label="$t('personalId.documentIssuedBy')"
                        v-model="documentIssuedBy"
                        :validation="$v.documentIssuedBy"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input
                        :label="$t('personalId.birthCity')"
                        v-model="birthCity"
                        :validation="$v.birthCity"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input-select
                        :label="$t('personalId.citizenship')"
                        v-model="citizenship"
                        :validation="$v.citizenship"
                        :options="countries"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input-radio-group
                        :id="'test__radio'"
                        :label="$t('personalId.sex')"
                        v-model="sex"
                        :validation="$v.sex"
                        :options="genders"
                        :stacked="false"
                    />
                </div>
            </div>
            <h3 class="mt-4">{{ $t('personalId.uploadsHeading') }}</h3>
            <form-input-radio-group
                :label="$t('personalId.documentType')"
                v-model="documentType"
                :validation="$v.documentType"
                :options="primarySelectOptions"
                :stacked="false"
            />
            <document-uploader
                v-if="documentType"
                class="mb-3"
                :document-type="documentType"
                :options="primaryDocumentTypesOptions"
                :uploader-url="$api.clientSessionUploads.uploaderUrl"
                :validation="$v.filteredStoredUploads"
                :uploads="uploads"
                :removed-uploads="removedUploads"
                :filtered-stored-uploads="filteredStoredUploads"
                @uploaded="onUploadUploaded"
                @remove="onUploadRemove"
                @restore="onUploadRestore"
            />
            <form-input-radio-group
                :label="$t('personalId.secondaryDocumentType')"
                v-model="secondaryDocumentType"
                :validation="$v.secondaryDocumentType"
                :options="secondarySelectOptions"
                :stacked="false"
            />
            <document-uploader
                v-if="secondaryDocumentType"
                :document-type="secondaryDocumentType"
                :options="secondaryDocumentTypesOptions"
                :uploader-url="$api.clientSessionUploads.uploaderUrl"
                :validation="$v.filteredStoredUploads"
                :uploads="uploads"
                :removed-uploads="removedUploads"
                :filtered-stored-uploads="filteredStoredUploads"
                @uploaded="onUploadUploaded"
                @remove="onUploadRemove"
                @restore="onUploadRestore"
            />

            <div class="mt-5">
                <b-btn
                    type="submit"
                    variant="primary"
                    class="mr-4 mb-2"
                >
                    {{$t('app.submit')}}
                </b-btn>

                <b-btn
                    variant="light"
                    @click.prevent="onCancel"
                    class="mb-2"
                >
                    {{$t('app.cancel')}}
                </b-btn>
            </div>
        </b-form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import * as objectHash from 'object-hash'
import DocumentUploader from '../../../../../Components/DocumentUploader.vue'

export default {
    name: 'PersonalIdForm',
    components: {
        DocumentUploader
    },
    props: {
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        },
        datePickerLang: {
            type: String,
            required: false,
            default: 'cz'
        },
        genders: {
            type: Array,
            required: false,
            default: () => []
        },
        countries: {
            type: Array,
            required: false,
            default: () => []
        },
        personalIdTypes: {
            type: Array,
            required: false,
            default: () => []
        },
        ivanHashId: {
            required: false,
            default: null
        }
    },
    data () {
        return {
            birthCity: this.defaults.birthCity || null,
            citizenship: this.defaults.citizenship || null,
            sex: this.defaults.sex || null,
            documentNumber: this.defaults.documentNumber || null,
            documentValidity: this.defaults.documentValidity || null,
            documentIssuedBy: this.defaults.documentIssuedBy || null,
            documentType: this.defaults.documentType || null,
            uploads: [],
            loadingUploads: false,
            removedUploads: [],
            secondaryDocumentType: this.defaults.secondaryDocumentType || null
        }
    },
    computed: {
        primarySelectOptions () {
            return this.primaryDocumentTypesOptions.map(option => ({
                value: option.value,
                text: option.text
            }))
        },
        primaryDocumentTypesOptions () {
            return [
                {
                    value: 'OP',
                    text: this.$t('personalIdTypes.OP'),
                    uploads: [
                        {
                            title: this.$t('personalId.opFrontTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'op.front'
                        },
                        {
                            title: this.$t('personalId.opBackTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'op.back'
                        }
                    ]
                },
                {
                    value: 'PAS',
                    text: this.$t('personalIdTypes.PAS'),
                    uploads: [
                        {
                            title: this.$t('personalId.pasTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pas'
                        }
                    ]
                },
                {
                    value: 'PP',
                    text: this.$t('personalIdTypes.PP'),
                    uploads: [
                        {
                            title: this.$t('personalId.ppFrontTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pp.front'
                        },
                        {
                            title: this.$t('personalId.ppBackTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pp.back'
                        }
                    ]
                }
            ]
        },
        secondarySelectOptions () {
            return this.secondaryDocumentTypesOptions.map(option => ({
                value: option.value,
                text: option.text
            }))
        },
        secondaryDocumentTypesOptions () {
            return [
                {
                    value: 'OP',
                    text: this.$t('personalIdTypes.OP'),
                    uploads: [
                        {
                            title: this.$t('personalId.opFrontTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'op.front'
                        },
                        {
                            title: this.$t('personalId.opBackTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'op.back'
                        }
                    ]
                },
                {
                    value: 'PAS',
                    text: this.$t('personalIdTypes.PAS'),
                    uploads: [
                        {
                            title: this.$t('personalId.pasTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pas'
                        }
                    ]
                },
                {
                    value: 'RP',
                    text: this.$t('personalIdTypes.RP'),
                    uploads: [
                        {
                            title: this.$t('personalId.rpTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'rp'
                        }
                    ]
                },
                {
                    value: 'PP',
                    text: this.$t('personalIdTypes.PP'),
                    uploads: [
                        {
                            title: this.$t('personalId.ppFrontTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pp.front'
                        },
                        {
                            title: this.$t('personalId.ppBackTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pp.back'
                        }
                    ]
                },
                {
                    value: 'ZP',
                    text: this.$t('personalIdTypes.ZP'),
                    uploads: [
                        {
                            title: this.$t('personalId.zpTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'zp'
                        }
                    ]
                }
            ].filter(item => item.value !== this.documentType)
        },
        storedUploads: {
            get () {
                return this.$store.state.uploads.personalId
            },
            set (value) {
                this.$store.commit('setPersonalIdUploads', value)
            }
        },
        filteredStoredUploads () {
            return this.storedUploads.filter(({ upload }) => !this.removedUploads.includes(upload))
        },
        dataHash () {
            const { removedUploads, loadingUploads, uploads, ...rest } = JSON.parse(JSON.stringify(this.$data))
            rest.uploads = [...this.filteredStoredUploads]
            return objectHash.sha1(rest)
        }
    },
    watch: {
        defaults () {
            this.$nextTick(() => {
                this.birthCity = this.defaults.birthCity || null
                this.citizenship = this.defaults.citizenship || null
                this.sex = this.defaults.sex || null
                this.documentNumber = this.defaults.documentNumber || null
                this.documentValidity = this.defaults.documentValidity || null
                this.documentIssuedBy = this.defaults.documentIssuedBy || null
                this.documentType = this.defaults.documentType || null
                this.secondaryDocumentType = this.defaults.secondaryDocumentType || null
            })
        },
        dataHash (newValue) {
            this.$store.commit('setDataHash', { name: 'personalId', hash: newValue })
            this.$store.commit('setFormData', { name: 'personalId', content: { ...this.$data, uploads: this.uploads.map(item => ({ upload: item._id, meta: item.meta })) } })
        }
    },
    mounted () {
        if (Array.isArray(this.defaults.uploads)) {
            const tmpMap = new Map(this.storedUploads.map(item => [item.upload, item]))
            for (const item of this.defaults.uploads) {
                if (!tmpMap.has(item.upload)) {
                    tmpMap.set(item.upload, item)
                }
            }
            this.storedUploads = [...tmpMap.values()]
        }
        this.$serverValidator.addHandler('personalId', this.onServerValidation)
        this.loadUploads()
            .then(() => {
                this.$store.commit('setDataHash', { name: 'personalIdOld', hash: ((Object.keys(this.defaults).length > 0) ? `${this.dataHash}` : null) })
                this.$store.commit('setDataHash', { name: 'personalId', hash: null })
                this.$store.commit('setFormData', { name: 'personalId', content: { ...this.$data, uploads: this.uploads.map(item => ({ upload: item._id, meta: item.meta })) } })
            })
    },
    beforeDestroy () {
        this.$serverValidator.removeHandler('personalId', this.onServerValidation)
    },
    methods: {
        onServerValidation (isValid) {
            if (!isValid) {
                this.$v.$touch()
                this.$notify.error(this.$t('errors.someDataIsMissing'))
            }
        },
        async loadUploads () {
            this.loadingUploads = true
            try {
                const { data } = await this.$api.clientSessionUploads.read()
                this.uploads = data.map(item => {
                    const found = this.storedUploads.find(upload => upload.upload === item._id)
                    return {
                        ...item,
                        meta: found?.meta ?? null
                    }
                }).filter(item => item.meta)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.cannotLoadUploads'))
            } finally {
                this.$nextTick(() => {
                    this.loadingUploads = false
                })
            }
        },
        async onUploadRemove (upload) {
            if (Object.keys(this.defaults).length > 0) {
                this.removedUploads.push(upload._id)
            } else {
                this.loadingUploads = true
                try {
                    await this.$api.clientSessionUploads.delete(upload._id)
                    this.storedUploads = this.storedUploads.filter(item => (item.upload !== upload._id))
                } catch (error) {
                    console.error(error)
                } finally {
                    this.loadUploads()
                }
            }
        },
        async onUploadUploaded (data, type) {
            const serverData = JSON.parse(data.serverId)
            if (Array.isArray(serverData) && serverData.length > 0) {
                const tmpMap = new Map(this.storedUploads.map(item => [item.upload, item]))
                for (const data of serverData) {
                    if (!tmpMap.has(data._id)) {
                        tmpMap.set(data._id, {
                            upload: data._id,
                            meta: type
                        })
                    }
                }
                this.storedUploads = [...tmpMap.values()]
                this.$nextTick(() => {
                    this.loadUploads()
                })
            }
        },
        onUploadRestore (upload) {
            this.removedUploads = this.removedUploads.filter(id => id !== upload._id)
        },
        onSubmit () {
            this.$v.$reset()
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                } else {
                    const { loadingUploads, removedUploads, ...data } = JSON.parse(JSON.stringify(this.$data))
                    data.uploads = [...this.filteredStoredUploads]
                    if (removedUploads.length > 0) {
                        Promise.all(removedUploads.map(id => this.$api.clientSessionUploads.delete(id))).catch(() => ({}))
                    }

                    const metaFields = [
                        this.primaryDocumentTypesOptions.find(item => item.value === this.documentType),
                        this.secondaryDocumentTypesOptions.find(item => item.value === this.secondaryDocumentType)
                    ].filter(item => item).map(item => (item?.uploads ?? []).map(upload => upload.metaField)).flat().filter(item => item)

                    const nonSelectedUploads = this.storedUploads.filter(item => !metaFields.includes(item.meta))

                    Promise.all(nonSelectedUploads.map(item => this.$api.clientSessionUploads.delete(item.upload))).catch(() => ({}))

                    data.uploads = data.uploads.filter(item => metaFields.includes(item.meta))

                    this.storedUploads = data.uploads
                    this.$emit('submit', data)
                }
            })
        },
        onCancel () {
            this.$store.commit('setDataHash', { name: 'personalIdOld', hash: null })
            this.$store.commit('setDataHash', { name: 'personalId', hash: null })
            this.$emit('cancel')
        }
    },
    validations () {
        const _this = this
        return {
            citizenship: {
                required
            },
            documentNumber: {
                required
            },
            documentIssuedBy: {
                required
            },
            documentValidity: {
                required,
                custom: function (value) {
                    const today = new Date()
                    today.setHours(0, 0, 0, 0)
                    if (value instanceof Date) {
                        return (value >= today)
                    } else {
                        try {
                            const date = new Date(value)
                            return (date >= today)
                        } catch (err) {
                            return false
                        }
                    }
                }
            },
            sex: {
                required
            },
            birthCity: {
                required
            },
            filteredStoredUploads: {
                custom (value) {
                    if (this.ivanHashId) {
                        return true
                    }
                    const metaFields = value.map(item => item.meta)
                    const foundPrimary = this.primaryDocumentTypesOptions.find(item => item.value === this.documentType)
                    const foundSecondary = this.secondaryDocumentTypesOptions.find(item => item.value === this.secondaryDocumentType)
                    if (foundPrimary && foundPrimary.uploads.filter(item => !metaFields.includes(item.metaField)).length > 0) {
                        return false
                    }
                    if (foundSecondary && foundSecondary.uploads.filter(item => !metaFields.includes(item.metaField)).length > 0) {
                        return false
                    }
                    return true
                }
            },
            documentType: {
                required
            },
            secondaryDocumentType: {
                required,
                custom: (value) => {
                    return value !== _this.documentType
                }
            }
        }
    }
}
</script>
